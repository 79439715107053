'use strict';

//////
////// Toggle Target Height component
//////

const $ = require('qwery');
const jump = require('jump.js');

/*

Required attributes:
    data-target

Optional attributes:
    data-target-active-class
    data-target-closed-height
    data-toggle-active-class
    data-animation-duraton
    data-animation-easing
    data-toggle-scroll-to { @type: Boolean }

*/

class ToggleTargetHeight {
    constructor($element) {
        // Elements
        this.$toggle = $element;
        this.$target = $(`.${this.$toggle.getAttribute('data-target')}`, document)[0];
        // Sets closed height for target element
        this.$targetClosedHeight = Number(this.$toggle.getAttribute('data-target-closed-height'));
        this.$targetHeightElement = this.$target.children[0];

        this.openByDefault = this.$toggle.hasAttribute('data-open-by-default');

        // Config
        this.toggleData = {
            targetActiveClass: this.$toggle.getAttribute('data-target-active-class') || 'active',
            toggleActiveClass: this.$toggle.getAttribute('data-toggle-active-class') || 'active',
            duration: this.$toggle.getAttribute('data-animation-duraton') || 500,
            easing: this.$toggle.getAttribute('data-animation-easing') || 'ease-in-out',
            scrollToElement: JSON.parse(this.$toggle.getAttribute('data-toggle-scroll-to')) || false,
        };

        // State
        this.state = {
            targetElementActive: false,
        };

        // Functions
        this.listToggleClick = this.listToggleClick.bind(this);
        this.animateHeight = this.animateHeight.bind(this);

        // Listeners
        this.$toggle.addEventListener('click', this.listToggleClick);

        // Initial Functions
        if(!this.openByDefault) {
            if (this.$targetClosedHeight) {
                this.targetHeight = this.$target.style.height = `${this.$targetClosedHeight}px`;
            } else {
                this.targetHeight = this.$target.style.height = '0px';
            }
        } else {
            this.state.targetElementActive = true;
            this.$target.classList.toggle(this.toggleData.targetActiveClass);
            this.$toggle.classList.toggle(this.toggleData.toggleActiveClass);
            this.targetHeight = '0px';
        }
    }

    listToggleClick() {
        // We know that the animation is running if === object
        if (typeof this.animation === 'object') {
            this.animation.reverse(); // Reverses the animation and fires the finished event
        } else {
            const height = this.$targetHeightElement.offsetHeight;
            this.animation = this.animateHeight(height);
        }

        this.animation.onfinish = () => {
            // Our target element will be open if its height is greater than the target height
            this.state.targetElementActive = this.$target.offsetHeight > this.$targetClosedHeight;
            this.animation = false;

            if (this.state.targetElementActive && this.toggleData.scrollToElement) {
                jump(this.$target, {
                    duration: this.toggleData.duration
                });
            }
        };

        this.$target.classList.toggle(this.toggleData.targetActiveClass);
        this.$toggle.classList.toggle(this.toggleData.toggleActiveClass);
    }

    animateHeight(height) {
        return this.$target.animate([
            {
                height: this.state.targetElementActive ? `${height}px` : this.targetHeight,
            },
            {
                height: this.state.targetElementActive ? this.targetHeight : `${height}px`,
            },
        ],
            {
                duration: this.toggleData.duration,
                easing: this.toggleData.easing,
                fill: 'forwards',
            }
        );
    }
}

module.exports = $element => new ToggleTargetHeight($element);
