'use strict';

const $ = require('qwery');

module.exports = function registerComponents(behaviours, store) {
    const components = $('[data-ui]');

    components.forEach((component) => {
        const uiAttr = component.getAttribute('data-ui');
        const elementBehaviours = uiAttr.split(' ');

        elementBehaviours.forEach((elem) => {
            const behaviour = elem.replace(/\W+(.)/g, (x, chr) => chr.toUpperCase());
            return behaviours[behaviour] ? behaviours[behaviour](component, store) : false;
        });
    });
};
