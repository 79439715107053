const $ = require('qwery');

const $html = $('html')[0];

$html.classList.add('js');

const behaviours = {
    toggleTargetHeight: require('../vendors/toggler'),
};

require('../vendors/cinder')(behaviours);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js').then((registration) => {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }).catch((err) => {
        // registration failed
        console.log('ServiceWorker registration failed: ', err);
    });
}

class Accordion {
    constructor(heading) {
        this.heading = heading;
    }

    showAll() {
        const accordionHeading = document.querySelectorAll(this.heading);

        accordionHeading.forEach((item) => {
            item.addEventListener('click', () => {
                // eslint-disable-next-line no-unused-expressions
                item.classList.contains('active') ? item.classList.remove('active') : item.classList.add('active');
            });
        });
    }
}

const accordion = new Accordion('.title');
accordion.showAll();
